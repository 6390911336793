var sectionHeadings = document.querySelectorAll(".landing-page__product-info h2");
sectionHeadings.forEach(function (section) {
  var observer = new IntersectionObserver(function (entries) {
    entries.forEach(function (entry) {
      if (entry.isIntersecting) {
        entry.target.closest(".landing-page__product-info").classList.add("fade-in");
        observer.unobserve(section);
      }
    });
  });
  observer.observe(section);
});